<template>
	<div class="i-c-p">
		<h4>{{ info.title }}</h4>
		<div class="row" :class="{ mask: info.isDynamicList && sumComputed === 0 }">
			<div class="chart-w">
				<div v-if="info.subTitInPie" class="tit-in-pie">
					<div class="tit">{{ info.subTitInPie }}</div>
					<div class="count">
						<span class="num">{{ info.total || sumComputed }}</span>
						{{ info.suffix }}
					</div>
				</div>
				<ECharts :option="option" />
			</div>
			<div class="right" :style="{ height: info.height + 'px' }">
				<div v-if="info.sumTitle" class="s-t">
					<h5>{{ info.sumTitle }}</h5>
					<div class="suffix">
						<span class="number" @click="jump('sum')">{{ info.total || sumComputed }}</span>
						{{ info.suffix }}
					</div>
				</div>
				<div
					class="list"
					:class="{ 'column-2': info.isTwoColumn }"
					:style="{ height: heightComputed + 'px' }"
				>
					<div v-for="(item, index) in info.list" :key="item[1]" class="l-i">
						<i :style="{ backgroundColor: item[0] }"></i>
						<div v-if="item[1]" class="c-1">{{ item[1] }}</div>
						<div v-if="item[2]" class="c-2">{{ item[2] }}</div>
						<div class="suffix">
							<span class="number" @click="jump('list', index)">{{ item[3] }}</span>
							<template v-if="item[3]">{{ info.suffix }}</template>
						</div>
					</div>
					<div v-for="(item, index) in info.subList" :key="item[1]" class="l-i">
						<div v-if="item[1]" class="c-1">{{ item[1] }}</div>
						<div v-if="item[2]" class="c-2">{{ item[2] }}</div>
						<div class="suffix">
							<span class="number" @click="jump('list', index)">{{ item[3] }}</span>
							<template v-if="item[3]">{{ item[4] }}</template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup>
import { computed, reactive, watch } from 'vue'
import { useRouter } from 'vue-router'
import ECharts from './ECharts'
// eslint-disable-next-line no-undef
const props = defineProps({
	info: {
		type: Object,
		default: () => ({
			title: '',
			sumTitle: '',
			subTitInPie: '', // 在圈里的标题
			suffix: '',
			isTwoColumn: false,
			isDynamicList: false, // 是否是动态列表
			originListLenth: 1, // 只有配置了isDynamicList需要配置此参数
			radius: '',
			roseType: '',
			height: 0,
			jump: null,
			sumJump: null,
			list: [[]],
			subList: [],
			total: '',
		}),
	},
})
const router = useRouter()
const sumComputed = computed(() =>
	props.info.list.reduce(
		(acc, cur) => ((acc += Number.isNaN(Number(cur[3])) ? 0 : Number(cur[3])), acc),
		0,
	),
)
const heightComputed = computed(() => {
	let h = props.info.height
	if (props.info.sumTitle) {
		h = props.info.height - 52
	}
	if (props.info.isDynamicList) {
		h =
			parseInt(h / props.info.originListLenth) *
			(props.info.list.length + (props.info.subList.length || 0))
	}
	return h
})
const option = reactive({
	tooltip: {
		trigger: 'item',
	},
	color: [],
	series: [
		{
			name: props.info.title,
			type: 'pie',
			radius: props.info.radius || '90%',
			data: [],
			label: {
				show: false,
				position: 'center',
			},
			labelLine: {
				show: false,
			},
			itemStyle: {
				borderColor: '#f9fafb',
				borderWidth: 3,
			},
			emptyCircleStyle: {
				color: '#dbdde0',
			},
			emphasis: {
				itemStyle: {
					shadowOffsetX: 0,
					shadowColor: 'rgba(0, 0, 0, 0.5)',
				},
			},
		},
	],
})
watch(
	() => props.info.list,
	val => {
		const list = val.filter(item => item[3] !== '-' && String(item[3]) !== '0')
		option.color = [...list.map(item => item[0])]
		option.series[0].data = [...list.map(item => ({ name: item[1], value: item[3] }))]
	},
	{ deep: true },
)

const jump = (flag, index) => {
	if (flag === 'sum' && !props.info.sumJump) return
	if (flag === 'list' && !props.info.jump) return
	const jumpParam = flag === 'sum' ? props.info.sumJump : props.info.jump[index]
	if (!jumpParam) return
	if (flag === 'list' && props.info.list[index][3] === '-') return
	let path = '',
		query = {}
	path = jumpParam.path
	for (const [k, v] of jumpParam?.query) {
		query = {
			...query,
			[k]: v,
		}
	}
	if (!path) return
	router.push({ path, query })
}
</script>
<style lang="less" scoped>
.chart-w {
	position: relative;
	width: 190px;
	height: 190px;
	.tit-in-pie {
		.flexible(row, center, center);
		flex-direction: column;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		.tit {
			font-size: 14px;
			line-height: 14px;
			color: #242b30;
			font-weight: 400;
			padding-bottom: 12px;
			margin-top: 10px;
		}
		.count {
			font-size: 14px;
			line-height: 14px;
			color: #6a7377;
			font-weight: 400;
			.num {
				color: #242b30;
				font-weight: 700;
				font-size: 24px;
				line-height: 24px;
				font-family: DIN-Alternate, PingFang SC, sans-serif;
				vertical-align: -2px;
			}
		}
	}
}
.list {
	display: flex;
	flex-direction: column;
	.l-i {
		.flexible(row, center, flex-start);
		font-size: 13px;
		color: #6a7377;
		line-height: 13px;
		flex: 1;
	}
	&.column-2 {
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;
		.l-i {
			flex: initial;
			width: 46%;
			margin-top: 20px;
		}
	}
	.c-1 {
		flex: 1;
		margin-left: 5px;
	}
	.c-2 {
		margin-right: 8px;
		width: 60px;
		text-align: right;
	}
	.number {
		font-size: 20px;
		line-height: 4px;
	}
	i {
		display: block;
		width: 14px;
		height: 14px;
	}
	.suffix {
		width: 70px;
		white-space: nowrap;
		text-align: right;
		visibility: visible;
	}
}
.right {
	flex: 1;
	margin-left: 15px;
	.s-t {
		.flexible(row, center, space-between);
		padding-bottom: 20px;
		border-bottom: 1px solid #f4f6f8;
		h5 {
			font-size: 14px;
			font-weight: 600;
			color: #242b30;
			margin: 0;
		}
		.suffix {
			font-size: 14px;
			font-weight: 400;
			color: #6a7377;
		}
		.number {
			font-size: 30px;
			line-height: 30px;
		}
	}
}
.number {
	color: #242b30;
	font-weight: 700;
	font-family: DIN-Alternate, PingFang SC, sans-serif;
}
.row {
	.flexible(row, center, space-between);
	padding: 20px 0 0;
}
.mask {
	background-image: linear-gradient(180deg, #f9fafbd9 0%, #f9fafb 100%);
}
.i-c-p {
	box-sizing: border-box;
	flex: 470px 1 1;
	padding: 16px 15px 0;
	background-color: #f9fafb;
	margin-right: 15px;
}
h4 {
	font-size: 16px;
	line-height: 16px;
	color: #242b30;
	font-weight: 600;
	margin: 0;
}
</style>
