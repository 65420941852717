<template>
	<div class="i-p" :class="{ gradient: isRange }">
		<h4>{{ info.title }}</h4>
		<div class="r-a" v-if="info.list" :class="{ small: String(info.list[0][0]).length >= 6 }">
			<div class="a">
				<span class="number" :class="{ gray: info.list[0][0] === '-' }" @click="jump(0)">
					{{ info.list[0][0] || '-' }}
				</span>
				{{ info.list[0][1] }}
			</div>
			<template v-if="info.list[1][0]">
				<div v-if="info.list[1][0] === 'isBar'" class="bar">
					<div
						class="on"
						:style="{
							width: parseInt(info.list[1][1]) > 100 ? 100 : parseInt(info.list[1][1]) + '%',
						}"
					></div>
				</div>
				<div v-else-if="info.list[1][0]" class="b">
					<i>{{ info.list[1][0] }}</i>
					<span :class="{ orange: info.list[1][3] === 'orange' }" @click="jump(1)">
						{{ info.list[1][1] }}
					</span>
					<template v-if="info.list[1][2]">{{ info.list[1][2] }}</template>
				</div>
			</template>
		</div>
		<div class="r-b" v-if="info.list">
			<div>
				{{ info.list[2][0] }}
				<span
					:class="{
						green: Number((info.list[2][1] || '').slice(0, -1)) > 0,
						red: Number((info.list[2][1] || '').slice(0, -1)) < 0,
					}"
				>
					{{ formateText(info.list[2][1]) }}
				</span>
			</div>
			<div v-if="info.list[3]">
				{{ info.list[3][0] }}
				<span
					:class="{
						green: Number((info.list[3][1] || '').slice(0, -1)) > 0,
						red: Number((info.list[3][1] || '').slice(0, -1)) < 0,
					}"
				>
					{{ formateText(info.list[3][1]) }}
				</span>
			</div>
		</div>
	</div>
</template>
<script setup>
import { useRouter } from 'vue-router'
// eslint-disable-next-line no-undef, no-unused-vars
const props = defineProps({
	info: {
		type: Object,
		// title: '出租率',
		// list: [
		// 		['97.00', '%'],
		//    ['总资产数', '600', '台'],
		//    ['较7日前', '+13.50'],
		//    ['较30日前', '+12.50'],
		// ],
		default: () => ({
			title: '',
			list: [[], [], [], []],
			jump: null,
			countInRow: 5, // 一行几个数据块
		}),
	},
	isRange: {
		type: Boolean,
		default: false,
	},
})
const router = useRouter()
const formateText = (text = '') => {
	const arr = text.split('.')
	if (arr.length === 2 && arr[0].length > 4) {
		return `${arr[0]}%`
	}
	return text
}
const jump = index => {
	if (!props.info.jump) return
	const jumpParam = props.info.jump[index]
	if (!jumpParam) return
	if (props.info.list[index][index] === '-') return
	let path = '',
		query = {}
	path = jumpParam.path
	for (const [k, v] of jumpParam?.query) {
		query = {
			...query,
			[k]: v,
		}
	}
	if (!path) return
	router.push({ path, query })
}
</script>
<style lang="less" scoped>
.r-a {
	.flexible(row, flex-end, flex-start);
	padding: 18px 0 13px;
	.number {
		font-size: 30px;
		line-height: 30px;
		font-weight: 700;
		color: #ff9423;
		vertical-align: -2px;
		margin-left: -5px;
		font-family: DIN-Alternate, PingFang SC, sans-serif;
	}
	.gray {
		color: #242b30;
	}
	&.small {
		.number {
			font-size: 22px;
		}
		.b {
			margin-bottom: 5px;
		}
	}
	.bar {
		position: relative;
		left: -15%;
		width: 45%;
		height: 8px;
		background: #e4e6ec;
		border-radius: 6px;
		margin-bottom: 6px;
		.on {
			height: 100%;
			background: #ff9423;
			border-radius: 6px;
		}
	}
	.a {
		flex: 1;
		color: #242b30;
		font-size: 16px;
		font-weight: 600;
		white-space: nowrap;
	}
	.b {
		flex: 1;
		font-size: 12px;
		font-weight: 400;
		color: #6a7377;
		white-space: nowrap;
		margin-bottom: 3px;
		i {
			font-style: normal;
		}
		span {
			color: #2eb3ff;
			padding: 0 3px;
			&.orange {
				color: #ff9423;
			}
		}
	}
}
.r-b {
	.flexible(row, center, flex-start);
	div {
		white-space: nowrap;
		font-size: 12px;
		font-weight: 400;
		color: #6a7377;
		flex: 1;
	}
	.green {
		color: #39bf66;
	}
	.red {
		color: #ff5858;
	}
}
.i-p {
	flex: 1;
	padding: 16px 2px 16px 15px;
	background: #f9fafb;
	margin-right: 15px;
	&.gradient {
		background-image: linear-gradient(180deg, #f4f8ff 0%, #fbfcff 100%);
	}
}
h4 {
	font-size: 16px;
	line-height: 16px;
	color: #242b30;
	font-weight: 600;
	margin: 0;
}
</style>
