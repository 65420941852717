<template>
	<div class="container">
		<div v-loading="loading" class="scroll-x">
			<div class="fx-start">
				<!-- <el-select v-model="companyIds" multiple collapse-tags @change="onChangeCompany">
					<el-option
						v-for="item in companyList"
						:key="item.code"
						:label="item.name"
						:value="item.code"
						:disabled="companyIds.length === 1 && companyIds.includes(item.code)"
					/>
				</el-select> -->
				<el-select
					v-model="companyIds"
					multiple
					collapse-tags
					:teleported="false"
					@change="onChangeCompany"
				>
					<el-checkbox-group v-model="companyIds">
						<el-option
							v-for="item in companyList"
							:key="item.code"
							:label="item.name"
							:value="item.code"
						>
							<el-checkbox :label="item.code" style="pointer-events: none">
								{{ item.name }}
							</el-checkbox>
						</el-option>
					</el-checkbox-group>

					<div class="option-bottom">
						<el-checkbox v-model="checkAll" @change="checkAllCompany">全选</el-checkbox>
						<el-checkbox v-model="checkReverse" @change="checkReverseCompany">反选</el-checkbox>
					</div>
				</el-select>
				<el-date-picker
					v-model="rangeDate"
					type="daterange"
					popper-class="yi-shortcus"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					size="small"
					value-format="YYYY-MM-DD"
					:disabled-date="disabledDate"
					:clearable="false"
					:shortcuts="shortcuts"
				/>
			</div>
			<div class="panel mt-10">
				<div class="h-wrap">
					<div class="head mr-15">资产运营</div>
					<DateBar
						:parent-date="rangeDate"
						:is-range="false"
						@change="e => onChangeDate('asset', e)"
					/>
				</div>
				<div class="row mt-15">
					<InfoPanel v-for="info in state.assetList" :key="info.title" :info="info" />
				</div>
				<div class="row mt-15">
					<InfoChartPanel :info="state.inventoryInfo" style="height: 300px" />
					<InfoChartPanel :info="state.carInfo" style="height: 300px" />
					<div class="year-data">
						<h5>年审数据</h5>
						<div class="yd-c">
							<div class="yd-r b-b">
								<div class="yd-i">
									<div class="tit">月年审达成率</div>
									<div class="count">
										<span class="number">{{ state.yearInfo.auditCarsRatio }}</span>
										%
									</div>
								</div>
								<div class="split"></div>
								<div class="yd-i">
									<div class="tit">本月累计年审数</div>
									<div class="count">
										<span class="number">{{ state.yearInfo.alreadyAuditCars }}</span>
										台
									</div>
								</div>
							</div>
							<div class="yd-r">
								<div class="yd-i">
									<div class="tit">本月应年审数</div>
									<div class="count">
										<span class="number">{{ state.yearInfo.needAuditCars }}</span>
										台
									</div>
								</div>
								<div style="flex: 1"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="panel mt-10">
				<div class="head mr-15">司机管理</div>
				<div class="mt-15">
					<DateBar
						:parent-date="rangeDate"
						:is-range="false"
						@change="e => onChangeDate('rent', e)"
					/>
				</div>
				<div class="row mt-12">
					<InfoPanel v-for="info in state.todayRentList" :key="info.title" :info="info" />
				</div>
				<div class="mt-15">
					<DateBar
						:parent-date="rangeDate"
						:is-range="true"
						@change="e => onChangeDate('rent', e)"
					/>
				</div>
				<div class="row mt-12">
					<InfoPanel
						v-for="info in state.rangeRentList"
						:key="info.title"
						:info="info"
						:is-range="true"
					/>
				</div>
			</div>
			<div class="panel mt-10">
				<div style="width: 75%">
					<div class="head mr-15">合同管理</div>
					<div class="mt-15">
						<DateBar
							:parent-date="rangeDate"
							:is-range="false"
							@change="e => onChangeDate('contract', e)"
						/>
					</div>
					<div class="row mt-12">
						<InfoPanel v-for="info in state.todayContractList" :key="info.title" :info="info" />
					</div>
					<div class="mt-15">
						<DateBar
							:parent-date="rangeDate"
							:is-range="true"
							@change="e => onChangeDate('contract', e)"
						/>
					</div>
					<div class="row mt-12">
						<InfoPanel
							v-for="info in state.rangeContractList"
							:key="info.title"
							:info="info"
							:is-range="true"
						/>
					</div>
				</div>
			</div>
			<div class="panel mt-10">
				<div class="head mr-15">维修保养</div>
				<div class="row">
					<div class="column-2">
						<DateBar
							:parent-date="rangeDate"
							:is-range="false"
							@change="e => onChangeDate('maintain', e)"
						/>
						<div class="mt-12">
							<InfoChartPanel :info="state.todayMaintainInfo" style="height: 270px" />
						</div>
					</div>
					<div class="column-2">
						<DateBar
							:parent-date="rangeDate"
							:is-range="true"
							@change="e => onChangeDate('maintain', e)"
						/>
						<div class="mt-12">
							<InfoChartPanel :info="state.rangeMaintainInfo" style="height: 270px" />
						</div>
					</div>
				</div>
				<div class="mt-15">
					<DateBar
						:parent-date="rangeDate"
						:is-range="false"
						@change="e => onChangeDate('maintainOfSum', e)"
					/>
				</div>
				<div class="row mt-12">
					<InfoPanel v-for="info in state.todayMaintainSumList" :key="info.title" :info="info" />
				</div>
				<div class="mt-15">
					<DateBar
						:parent-date="rangeDate"
						:is-range="true"
						@change="e => onChangeDate('maintainOfSum', e)"
					/>
				</div>
				<div class="row mt-12">
					<InfoPanel
						v-for="info in state.rangeMaintainSumList"
						:key="info.title"
						:info="info"
						:is-range="true"
					/>
				</div>
			</div>
			<div class="panel mt-10">
				<div class="head mr-15">事故数据</div>
				<div class="row">
					<div class="column-2">
						<DateBar
							:parent-date="rangeDate"
							:is-range="false"
							@change="e => onChangeDate('accident', e)"
						/>
						<div class="mt-12">
							<InfoChartPanel :info="state.todayAccidentInfo" style="height: 270px" />
						</div>
						<div class="mt-15">
							<DateBar
								:parent-date="rangeDate"
								:is-range="false"
								@change="e => onChangeDate('accidentOfDuty', e)"
							/>
						</div>
						<div class="row mt-12">
							<InfoPanel v-for="info in state.todayAccidentList" :key="info.title" :info="info" />
						</div>
					</div>
					<div class="column-2">
						<DateBar
							:parent-date="rangeDate"
							:is-range="true"
							@change="e => onChangeDate('accident', e)"
						/>
						<div class="mt-12">
							<InfoChartPanel :info="state.rangeAccidentInfo" style="height: 270px" />
						</div>
						<div class="mt-15">
							<DateBar
								:parent-date="rangeDate"
								:is-range="true"
								@change="e => onChangeDate('accidentOfDuty', e)"
							/>
						</div>
						<div class="row mt-12">
							<InfoPanel
								v-for="info in state.rangeAccidentList"
								:key="info.title"
								:info="info"
								:is-range="true"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'ChartOperate',
})
</script>
<script setup name="ChartOperate">
import { ref, reactive, inject, computed } from 'vue'
import dayjs from 'dayjs'
import DateBar from './components/DateBar'
import InfoPanel from './components/InfoPanel'
import InfoChartPanel from './components/InfoChartPanel'
import { useFetch } from '@/utils/hooks.js'
import * as API from '@/api/chart'
import { companySelect } from '@/api/chart'

const rangeDate = ref('')
const companyIds = ref([])
const companyList = ref([])
const loading = ref(false)
const checkAll = ref(false)
const checkReverse = ref(false)
const $message = inject('$message')

// const getModel = computed(() => (multiple.value ? companyIds.value : companyIds.value[0]))
// const onSwitch = () => {
// 	multiple.value = !multiple.value
// 	companyIds.value = companyIds.value.length ? [companyIds.value[0]] : []
// 	getRealData()
// }
const shortcuts = [
	{
		text: '昨日',
		value: () => {
			const start = new Date()
			start.setTime(start.getTime() - 3600 * 1000 * 24)
			return [start, start]
		},
	},
	{
		text: '前日',
		value: () => {
			const start = new Date()
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 2)
			return [start, start]
		},
	},
	{
		text: '本周',
		value: () => {
			const start = dayjs().subtract(1, 'day').day(1)
			const end = dayjs().subtract(1, 'day')
			if (start.isBefore(end)) {
				return [start, end]
			}
			return [start, start]
		},
	},
	{
		text: '上周',
		value: () => {
			const start = dayjs().subtract(8, 'day').day(1)
			const end = dayjs().day(0)
			return [start, end]
		},
	},
	{
		text: '本月',
		value: () => {
			if (dayjs().date() === 1) {
				const start = dayjs().subtract(1)
				const end = start.endOf('month')
				return [start.date(1), end]
			}
			return [dayjs().startOf('month'), dayjs().subtract(1, 'day')]
		},
	},
	{
		text: '上月',
		value: () => {
			const now = dayjs()
			const start = now.month(now.month() - 1).date(1)
			const end = now.month(now.month() - 1).endOf('month')
			return [start, end]
		},
	},
	{
		text: '过去7天',
		value: () => {
			const now = dayjs()
			const start = now.subtract(7, 'day')
			const end = now.subtract(1, 'day')
			return [start, end]
		},
	},
	{
		text: '过去30天',
		value: () => {
			const now = dayjs()
			const start = now.subtract(30, 'day')
			const end = now.subtract(1, 'day')
			return [start, end]
		},
	},
	{
		text: '过去90天',
		value: () => {
			const now = dayjs()
			const start = now.subtract(90, 'day')
			const end = now.subtract(1, 'day')
			return [start, end]
		},
	},
	{
		text: '今年至今',
		value: () => {
			const now = dayjs()
			const start = now.startOf('year')
			const end = now.subtract(1, 'day')
			return [start, end]
		},
	},
]
const query = reactive({
	assetDate: '',
	rentDate: '',
	rentRange: '',
	rentType: '',
	contractDate: '',
	contractRange: '',
	contractType: '',
	maintainDate: '',
	maintainRange: '',
	maintainType: '',
	maintainOfSumDate: '',
	maintainOfSumRange: '',
	maintainOfSumType: '',
	accidentDate: '',
	accidentRange: '',
	accidentType: '',
	accidentOfDutyDate: '',
	accidentOfDutyRange: '',
	accidentOfDutyType: '',
})
const state = reactive({
	assetList: [], // 资产运营
	inventoryInfo: {}, // 库存数据
	carInfo: {}, // 出租中车辆数据
	yearInfo: {}, // 年审数据
	todayRentList: [], // 司机管理-日租金
	rangeRentList: [], // 司机管理-期间租金
	todayContractList: [], // 合同管理-本日数据
	rangeContractList: [], // 合同管理-期间数据
	todayMaintainInfo: {}, // 维修保养-本日数据
	rangeMaintainInfo: {}, // 维修保养-期间数据
	todayMaintainSumList: [], // 维修保养-本日金额数据
	rangeMaintainSumList: [], // 维修保养-期间金额数据
	todayAccidentInfo: [], // 事故数据-本日事故数据
	rangeAccidentInfo: [], // 事故数据-期间累计事故数据
	todayAccidentList: [], // 事故数据-本日事故总数据
	rangeAccidentList: [], // 事故数据-期间事故总数据
})
const { fetchData } = useFetch()
const requestFn = async (params, requestMethod, callback) => {
	try {
		const { code, msg, data } = await API[requestMethod]({
			companyIds: companyIds.value,
			...params,
		})
		if (code !== 200) throw new Error(msg)
		callback(data)
	} catch (err) {
		$message.error(err.message)
	} finally {
		loading.value = false
	}
}
const actuator = {
	getAssetData: () => {
		// 资源运营-前5个+库存
		requestFn({ dataDate: query.assetDate }, 'getAssetList', data => {
			state.assetList[0].list[0][0] = data?.letRate
			state.assetList[0].list[1][1] = data?.cars
			state.assetList[0].list[2][1] = data?.letRate7Ago
			state.assetList[0].list[3][1] = data?.letRate30Ago

			state.assetList[1].list[0][0] = data?.stockCars
			state.assetList[1].list[2][1] = data?.stockCars7Ago
			state.assetList[1].list[3][1] = data?.stockCars30Ago

			state.assetList[2].list[0][0] = data?.workingCars
			state.assetList[2].list[2][1] = data?.workingCars7Ago
			state.assetList[2].list[3][1] = data?.workingCars30Ago

			state.assetList[3].list[0][0] = data?.referCars
			state.assetList[3].list[2][1] = data?.referCars7Ago
			state.assetList[3].list[3][1] = data?.referCars30Ago
			state.assetList[4].list[0][0] = data?.backCars
			state.assetList[4].list[2][1] = data?.backCars7Ago
			state.assetList[4].list[3][1] = data?.backCars30Ago

			state.inventoryInfo.total = data?.stockCars
			state.inventoryInfo.list[0][2] = data?.toBeLetProportionOf // 待出租车辆数占比
			state.inventoryInfo.list[0][3] = data?.toBeLet // 待出租车辆数
			state.inventoryInfo.list[1][2] = data?.returnableCarsProportionOf // 待退车车辆数占比
			state.inventoryInfo.list[1][3] = data?.returnableCars // 待退车车辆数
			state.inventoryInfo.list[2][2] = data?.waitReferCarsProportionOf // 待交车车辆数占比
			state.inventoryInfo.list[2][3] = data?.waitReferCars // 待交车车辆数
			state.inventoryInfo.list[3][2] = data?.toBePreparedProportionOf // 待整备车辆数占比
			state.inventoryInfo.list[3][3] = data?.toBePrepared // 待整备车辆数
			state.inventoryInfo.list[4][2] = data?.toBeRepairedProportionOf // 待维修车辆数占比
			state.inventoryInfo.list[4][3] = data?.toBeRepaired // 待维修车辆数
			state.inventoryInfo.list[5][2] = data?.inMaintenanceProportionOf // 维修中车辆数占比
			state.inventoryInfo.list[5][3] = data?.inMaintenance // 维修中车辆数
		})
		// 资源运营-出租中车辆数据
		requestFn({ dataDate: query.assetDate }, 'getCarInfo', data => {
			state.carInfo.total = data.carTotalNum
			if (data.list) {
				const colors = ['#FF7A42', '#FFA742', '#FFAB87', '#DBDDE0', '#FFBB1E', '#FFD87D', '', '']
				for (let i = 0; i < data.list.length; i++) {
					state.carInfo.list[i] = [
						colors[i],
						data.list[i].contractPeriod,
						data.list[i].percent,
						data.list[i].workingCars,
					]
					state.carInfo.jump[i] = {
						path: '/driver-contract',
						query: [
							['period', data.list[i].sort],
							['companyIds', companyIds.value.join(',')],
						],
					}
				}
				state.carInfo.list = state.carInfo.list.slice(0, data.list?.length)
				state.carInfo.jump = state.carInfo.jump.slice(0, data.list?.length)
				if (data?.dueNotReturnPercent) {
					state.carInfo.subList[0] = [
						'',
						'到期未退',
						data?.dueNotReturnPercent,
						data?.dueNotReturnCount,
						'辆',
					]
				}
				if (data?.dueNotReturnPercent) {
					state.carInfo.subList[1] = ['', '期均数', '', data?.contractPeriodMean]
				}
			} else {
				state.carInfo.list = [
					['#FF7A42', '3期', '-', '-'],
					['#FFA742', '6期', '-', '-'],
					['#FFAB87', '9期', '-', '-'],
					['#DBDDE0', '12期', '-', '-'],
					['#FFBB1E', '24期', '-', '-'],
					['#FFD87D', '36期', '-', '-'],
				]
				state.carInfo.subList = [
					['', '到期未退', '_', '_'],
					['', '期均数', '_'],
				]
			}
		})
		// 资源运营-年审数据
		requestFn({ dataDate: query.assetDate }, 'getYearInfo', data => {
			state.yearInfo.auditCarsRatio = data?.auditCarsRatio
			state.yearInfo.alreadyAuditCars = data?.alreadyAuditCars
			state.yearInfo.needAuditCars = data?.needAuditCars
		})
	},
	getRentData: isRange => {
		if (!isRange) {
			// 司机管理-租金日数据
			requestFn({ dataDate: query.rentDate }, 'getTodayRentList', data => {
				const groups = ['statistics', 'receivable', 'actual', 'arrears']
				const keys = ['receiveRate', 'receivableRent', 'actualRent', 'actualArrears']
				groups.forEach((group, index) => {
					state.todayRentList[index].list[0][0] = data && data[group] && data[group][keys[index]]
					if (index === 0) {
						state.todayRentList[index].list[1][1] =
							data && data[group]?.receiveRate === '-' ? 0 : data[group]?.receiveRate
					} else {
						state.todayRentList[index].list[1][1] = data && data[group]?.driverCount
					}
					state.todayRentList[index].list[2][1] = data && data[group]?.compare7
					state.todayRentList[index].list[3][1] = data && data[group]?.compare30
				})
			})
		} else {
			// 司机管理-租金周期数据
			requestFn(
				{ startDate: query.rentRange[0], endDate: query.rentRange[1], periodType: query.rentType },
				'getRangeRentList',
				data => {
					const groups = ['statistics', 'receivable', 'actual', 'arrears']
					const keys = ['receiveRate', 'receivableRent', 'actualRent', 'actualArrears']
					const text = ['期间', '周', '月'][query.rentType]
					groups.forEach((group, index) => {
						state.rangeRentList[index].list[0][0] = data && data[group] && data[group][keys[index]]
						if (index === 0) {
							state.rangeRentList[index].list[1][1] = data && data[group]?.receiveRate
						} else {
							const tit = query.rentType === 1 ? '本周' : query.rentType === 2 ? '本月' : '期间'
							const rep = state.rangeRentList[index].list[1][0].replace(/(本月)?(期间)?(本周)?/, '')
							state.rangeRentList[index].list[1][0] = tit + rep
							state.rangeRentList[index].list[1][1] = data && data[group]?.driverCount
						}
						state.rangeRentList[index].list[2][0] = `${text}环比`
						state.rangeRentList[index].list[2][1] = data && data[group]?.chainRatio
						state.rangeRentList[index].list[3][0] = `${text}同比`
						state.rangeRentList[index].list[3][1] = data && data[group]?.yearOnYearRatio
					})
				},
			)
		}
	},
	getContractData: isRange => {
		if (!isRange && query.contractDate) {
			// 合同管理-日数据
			requestFn({ dataDate: query.contractDate }, 'getTodayContractList', data => {
				const groups = ['firstSignContract', 'continueSignContract', 'breachPenaltyRevenue']
				const keys = ['num', 'num', 'breachPenalty']
				groups.forEach((group, index) => {
					state.todayContractList[index].list[0][0] =
						data && data[group] && data[group][keys[index]]
					state.todayContractList[index].list[1][1] = data && data[group]?.renewalRate
					state.todayContractList[index].list[2][1] = data && data[group]?.compare7
					state.todayContractList[index].list[3][1] = data && data[group]?.compare30
				})
			})
		} else {
			// 合同管理-周期数据
			requestFn(
				{
					startDate: query.contractRange[0],
					endDate: query.contractRange[1],
					periodType: query.contractType,
				},
				'getRangeContractList',
				data => {
					const groups = ['firstSignContract', 'continueSignContract', 'breachPenaltyRevenue']
					const keys = ['num', 'num', 'breachPenalty']
					const text = ['期间', '周', '月'][query.contractType]
					groups.forEach((group, index) => {
						state.rangeContractList[index].list[0][0] =
							data && data[group] && data[group][keys[index]]
						if (index === 1) {
							state.rangeContractList[index].list[1][1] = data && data[group]?.renewalRate
						}
						state.rangeContractList[index].list[2][1] = data && data[group]?.chainRatio
						state.rangeContractList[index].list[3][1] = data && data[group]?.yearOnYearRatio
					})
				},
			)
		}
	},
	getMaintainData: isRange => {
		if (!isRange) {
			// 维保保养-日数据
			requestFn({ dataDate: query.maintainDate }, 'getTodayMaintainInfo', data => {
				const keys = ['service', 'keep', 'wear', 'hostLing']
				keys.forEach((item, index) => {
					state.todayMaintainInfo.list[index][2] = data && data[`${item}Percentage`]
					state.todayMaintainInfo.list[index][3] = data && data[`${item}Cars`]
				})
			})
		} else {
			// 维保保养-周期数据
			requestFn(
				{
					startDate: query.maintainRange[0],
					endDate: query.maintainRange[1],
					periodType: query.maintainType,
				},
				'getRangeMaintainInfo',
				data => {
					const keys = ['service', 'keep', 'wear', 'hostLing']
					keys.forEach((item, index) => {
						state.rangeMaintainInfo.list[index][2] = data && data[`${item}Percentage`]
						state.rangeMaintainInfo.list[index][3] = data && data[`${item}Cars`]
					})
				},
			)
		}
	},
	getMaintainOfSumData: isRange => {
		if (!isRange) {
			// 维保保养-金额日数据
			requestFn({ dataDate: query.maintainOfSumDate }, 'getTodayMaintainSumList', data => {
				const keys = ['service', 'keep', 'wear', 'hostLing', 'damage']
				keys.forEach((item, index) => {
					state.todayMaintainSumList[index].list[0][0] = data && data && data[`${item}Amount`]
					state.todayMaintainSumList[index].list[1][1] = data && data[`${item}Cars`]
					state.todayMaintainSumList[index].list[2][1] = data && data[`${item}Compare7Days`]
					state.todayMaintainSumList[index].list[3][1] = data && data[`${item}Compare30Days`]
				})
			})
		} else {
			// 维保保养-金额周期数据
			requestFn(
				{
					startDate: query.maintainOfSumRange[0],
					endDate: query.maintainOfSumRange[1],
					periodType: query.maintainOfSumType,
				},
				'getRangeMaintainSumList',
				data => {
					const keys = ['service', 'keep', 'wear', 'hostLing', 'damage']
					const text = ['期间', '周', '月'][query.maintainOfSumType]
					keys.forEach((item, index) => {
						state.rangeMaintainSumList[index].list[0][0] = data && data && data[`${item}Amount`]
						state.rangeMaintainSumList[index].list[1][1] = data && data[`${item}Cars`]
						state.rangeMaintainSumList[index].list[2][0] = `${text}环比`
						state.rangeMaintainSumList[index].list[2][1] = data && data[`${item}Mom`]
						state.rangeMaintainSumList[index].list[3][0] = `${text}同比`
						state.rangeMaintainSumList[index].list[3][1] = data && data[`${item}Yoy`]
					})
				},
			)
		}
	},
	getAccidentData: isRange => {
		if (!isRange) {
			// 事故-日数据
			requestFn({ dataDate: query.accidentDate }, 'getTodayAccidentInfo', data => {
				const keys = ['full', 'main', 'flat', 'second', 'no']
				keys.forEach((item, index) => {
					state.todayAccidentInfo.list[index][2] = data && data[`${item}AccidentsPercentage`]
					state.todayAccidentInfo.list[index][3] = data && data[`${item}Accidents`]
				})
			})
		} else {
			// 事故-周期数据
			requestFn(
				{
					startDate: query.accidentRange[0],
					endDate: query.accidentRange[1],
					periodType: query.accidentType,
				},
				'getRangeAccidentInfo',
				data => {
					const keys = ['full', 'main', 'flat', 'second', 'no']
					keys.forEach((item, index) => {
						state.rangeAccidentInfo.list[index][2] = data && data[`${item}AccidentsPercentage`]
						state.rangeAccidentInfo.list[index][3] = data && data[`${item}Accidents`]
					})
				},
			)
		}
	},
	getAccidentOfDutyData: isRange => {
		if (!isRange) {
			// 事故-有责日数据
			requestFn({ dataDate: query.accidentOfDutyDate }, 'getTodayAccidentList', data => {
				state.todayAccidentList[0].list[0][0] = data?.errAccidents
				state.todayAccidentList[0].list[2][1] = data?.compare7
				state.todayAccidentList[0].list[3][1] = data?.compare30

				state.todayAccidentList[1].list[0][0] = data?.insFloatAmt
				state.todayAccidentList[1].list[2][1] = data?.insFloatAmtCompare7
				state.todayAccidentList[1].list[3][1] = data?.insFloatAmtCompare30

				state.todayAccidentList[2].list[0][0] = data?.repairBenefit
				state.todayAccidentList[2].list[2][1] = data?.repairBenefitCompare7
				state.todayAccidentList[2].list[3][1] = data?.repairBenefitCompare30
			})
		} else {
			// 事故-有责周期数据
			requestFn(
				{
					startDate: query.accidentOfDutyRange[0],
					endDate: query.accidentOfDutyRange[1],
					periodType: query.accidentOfDutyType,
				},
				'getRangeAccidentList',
				data => {
					const text = ['期间', '周', '月'][query.accidentOfDutyType]
					state.rangeAccidentList[0].list[0][0] = data?.errAccidents
					state.rangeAccidentList[0].list[2][0] = `${text}环比`
					state.rangeAccidentList[0].list[2][1] = data?.monthOnMonth
					state.rangeAccidentList[0].list[3][0] = `${text}同比`
					state.rangeAccidentList[0].list[3][1] = data?.yearOnYear

					state.rangeAccidentList[1].list[0][0] = data?.insFloatAmt
					state.rangeAccidentList[1].list[2][1] = data?.insFloatAmtMonthOnMonth
					state.rangeAccidentList[1].list[3][1] = data?.insFloatAmtYearOnYear

					state.rangeAccidentList[2].list[0][0] = data?.repairBenefit
					state.rangeAccidentList[2].list[2][1] = data?.repairBenefitMonthOnMonth
					state.rangeAccidentList[2].list[3][1] = data?.repairBenefitYearOnYear
				},
			)
		}
	},
}
const getRealData = () => {
	loading.value = true
	actuator.getAssetData()
	actuator.getRentData()
	actuator.getRentData(true)
	actuator.getContractData()
	actuator.getContractData(true)
	actuator.getMaintainData()
	actuator.getMaintainData(true)
	actuator.getMaintainOfSumData()
	actuator.getMaintainOfSumData(true)
	actuator.getAccidentData()
	actuator.getAccidentData(true)
	actuator.getAccidentOfDutyData()
	actuator.getAccidentOfDutyData(true)
}
const fetchCompanyList = async () => {
	const { err, data } = await fetchData(companySelect, { isPermission: true })
	if (err) $message.error(err)
	companyIds.value = [data.map(item => item.companyId)[0]]
	companyList.value = data.map(item => ({ name: item.companyName, code: item.companyId }))
	getRealData()
	setJumpCompanyIds()
}
const onChangeDate = (flag, { date, range, type }) => {
	if (date) {
		query[`${flag}Date`] = date.split('/').join('-')
		if (flag === 'asset') {
			state.assetList[3].jump[0].query[0][1] = query.assetDate
			state.assetList[4].jump[0].query[0][1] = query.assetDate
		}
	} else {
		query[`${flag}Range`] = range.map(item => item.split('/').join('-'))
		query[`${flag}Type`] = type
	}
	// 避免接口2次调用
	if (companyIds.value.length !== 0) {
		const upper = flag.slice(0, 1).toUpperCase()
		actuator[`get${upper + flag.slice(1)}Data`](range)
	}
}
const setJumpCompanyIds = () => {
	// 当所选公司不是所有公司列表的时候需要显示传入公司id
	if (companyIds.value.length === companyList.value.length) return
	const ids = companyIds.value.join(',')
	state.assetList.forEach(item => {
		item.jump.forEach(jump => {
			jump.query = [...jump.query.filter(query => query[0] !== 'companyIds'), ['companyIds', ids]]
		})
	})
	state.inventoryInfo.jump.forEach(jump => {
		jump.query = [...jump.query.filter(query => query[0] !== 'companyIds'), ['companyIds', ids]]
	})
	state.carInfo.jump.forEach(jump => {
		jump.query = [...jump.query.filter(query => query[0] !== 'companyIds'), ['companyIds', ids]]
	})
	state.inventoryInfo.sumJump.query = [state.inventoryInfo.sumJump.query[0], ['companyIds', ids]]
}
const onChangeCompany = val => {
	if (val.length === 0) {
		$message.warning('请至少选择一个公司')
		return
	}
	if (val.length === companyList.value.length) {
		checkAll.value = true
	} else {
		checkAll.value = false
	}
	setJumpCompanyIds()
	getRealData()
}
const checkAllCompany = () => {
	// // 全选情况下, 再次点击, 默认勾选第1个公司
	if (checkAll.value === false) {
		companyIds.value = [companyList.value[0].code]
	} else {
		companyIds.value = companyList.value.map(item => item.code)
	}
	checkReverse.value = false
	setJumpCompanyIds()
	getRealData()
}
const checkReverseCompany = () => {
	// 全选情况下, 点击反选, 默认勾选第1个公司
	if (checkAll.value === true) {
		companyIds.value = [companyList.value[0].code]
	} else {
		let curSelects = companyIds.value
		companyIds.value = companyList.value
			.filter(item => !curSelects.includes(item.code))
			.map(item => item.code)
	}
	checkAll.value = false
	setJumpCompanyIds()
	getRealData()
}
const disabledDate = time => {
	return time.getTime() > dayjs().subtract(1, 'day').valueOf()
}
const initData = () => {
	const first = dayjs().subtract(1, 'day').date(1).format('YYYY/MM/DD')
	const last = dayjs().subtract(1, 'day').format('YYYY/MM/DD')
	// 默认日期：当月1号-昨天
	rangeDate.value = [first, last]

	state.assetList = [
		{
			title: '出租率',
			list: [
				['-', '%'],
				['总资产数', '-', '台', 'orange'],
				['较7日前', '-'],
				['较30日前', '-'],
			],
			jump: [
				{
					path: '/vehicle-management',
					query: [['statusList', '5,7']],
				},
				{
					path: '/vehicle-management',
					query: [['statusList', '1,2,3,4,5,6,7']],
				},
			],
		},
		{
			title: '库存车辆',
			list: [['-', '台'], [], ['较7日前', '-'], ['较30日前', '-']],
			jump: [
				{
					path: '/vehicle-management',
					query: [['statusList', '1,2,3,4,5,6']],
				},
			],
		},
		{
			title: '出租中车辆',
			list: [['-', '台'], [], ['较7日前', '-'], ['较30日前', '-']],
			jump: [
				{
					path: '/vehicle-management',
					query: [['statusList', '6,7']],
				},
			],
		},
		{
			title: '已交车',
			list: [['-', '台'], [], ['较7日前', '-'], ['较30日前', '-']],
			jump: [
				{
					path: '/vehicle-delivery',
					query: [
						['deliveryTime', dayjs().subtract(1, 'day').format('YYYY-MM-DD')],
						['result', 1],
					],
				},
			],
		},
		{
			title: '已退车',
			list: [['-', '台'], [], ['较7日前', '-'], ['较30日前', '-']],
			jump: [
				{
					path: '/vehicle-check',
					query: [['inspectTime', dayjs().subtract(1, 'day').format('YYYY-MM-DD')]],
				},
			],
		},
	]
	state.inventoryInfo = {
		title: '库存数据',
		sumTitle: '库存车辆总数',
		suffix: '辆',
		radius: '90%',
		height: 234,
		total: 0,
		list: [
			['#FF7A42', '待出租', '-', '-'],
			['#FFA742', '待退车', '-', '-'],
			['#FFAB87', '待交车', '-', '-'],
			['#DBDDE0', '待整备', '-', '-'],
			['#FFBB1E', '待维修', '-', '-'],
			['#FFD87D', '维修中', '-', '-'],
		],
		jump: [
			{
				path: '/vehicle-management',
				query: [
					['statusList', '4'],
					['isInStock', true],
				],
			},
			{
				path: '/vehicle-management',
				query: [
					['statusList', '6'],
					['isInStock', true],
				],
			},
			{
				path: '/vehicle-management',
				query: [
					['statusList', '5'],
					['isInStock', true],
				],
			},
			{
				path: '/vehicle-management',
				query: [
					['statusList', '1'],
					['isInStock', true],
				],
			},
			{
				path: '/vehicle-management',
				query: [
					['statusList', '2'],
					['isInStock', true],
				],
			},
			{
				path: '/vehicle-management',
				query: [
					['statusList', '3'],
					['isInStock', true],
				],
			},
		],
		sumJump: {
			path: '/vehicle-management',
			query: [['statusList', '1,2,3,4,5,6']],
		},
	}
	state.carInfo = {
		title: '出租中车辆数据',
		subTitInPie: '出租中车辆总数',
		suffix: '辆',
		radius: ['75%', '90'],
		height: 204,
		isDynamicList: true,
		originListLenth: 7,
		total: 0,
		list: [
			['#FF7A42', '3期', '-', '-'],
			['#FFA742', '6期', '-', '-'],
			['#FFAB87', '9期', '-', '-'],
			['#DBDDE0', '12期', '-', '-'],
			['#FFBB1E', '24期', '-', '-'],
			['#FFD87D', '36期', '-', '-'],
		],
		subList: [
			['#FFBB1E', '到期未退', '', '-', '辆'],
			['#FFD87D', '期均数', '', '-', ''],
		],
		jump: [
			{
				path: '/driver-contract',
				query: [['period', 3]],
			},
			{
				path: '/driver-contract',
				query: [],
			},
			{
				path: '/driver-contract',
				query: [['period', 9]],
			},
			{
				path: '/driver-contract',
				query: [['period', 12]],
			},
			{
				path: '/driver-contract',
				query: [['period', 24]],
			},
			{
				path: '/driver-contract',
				query: [['period', 36]],
			},
		],
	}
	state.todayRentList = [
		{
			title: '租金日收取率',
			list: [
				['-', '%'],
				['isBar', '-'],
				['较7日前', '-'],
				['较30日前', '-'],
			],
		},
		{
			title: '今日应收租金',
			list: [
				['-', '万元'],
				['今日应缴司机人数', '-', '人'],
				['较7日前', '-'],
				['较30日前', '-'],
			],
		},
		{
			title: '今日实收租金',
			list: [
				['-', '万元'],
				['今日实缴司机人数', '-', '人'],
				['较7日前', '-'],
				['较30日前', '-'],
			],
		},
		{
			title: '今日实欠租金',
			list: [
				['-', '万元'],
				['今日欠租司机人数', '-', '人'],
				['较7日前', '-'],
				['较30日前', '-'],
			],
		},
	]
	state.rangeRentList = [
		{
			title: '租金期间收取率',
			list: [
				['-', '%'],
				['isBar', '-'],
				['环比', '-'],
				['同比', '-'],
			],
		},
		{
			title: '期间应收租金',
			list: [
				['-', '万元'],
				['应缴司机人数', '-', '人'],
				['环比', '-'],
				['同比', '-'],
			],
		},
		{
			title: '期间实收租金',
			list: [
				['-', '万元'],
				['实缴司机人数', '-', '人'],
				['环比', '-'],
				['同比', '-'],
			],
		},
		{
			title: '期间实欠租金',
			list: [
				['-', '万元'],
				['欠租司机人数', '-', '人'],
				['环比', '-'],
				['同比', '-'],
			],
		},
	]
	state.todayContractList = [
		{
			title: '今日合同新签',
			list: [['-', '笔'], [], ['较7日前', '-'], ['较30日前', '-']],
		},
		{
			title: '今日合同续签',
			list: [
				['-', '笔'],
				['续签率', '-'],
				['较7日前', '-'],
				['较30日前', '-'],
			],
		},
		{
			title: '今日违约金收入',
			list: [['-', '万元'], [], ['较7日前', '-'], ['较30日前', '-']],
		},
		{},
	]
	state.rangeContractList = [
		{
			title: '期间合同新签',
			list: [['-', '笔'], [], ['期间环比', '-'], ['期间同比', '-']],
		},
		{
			title: '期间合同续签',
			list: [
				['-', '笔'],
				['续签率', '-'],
				['期间环比', '-'],
				['期间同比', '-'],
			],
		},
		{
			title: '期间违约金收入',
			list: [['-', '万元'], [], ['期间环比', '-'], ['期间同比', '-']],
		},
	]
	state.todayMaintainInfo = {
		title: '本日维保数据',
		sumTitle: '维保总数',
		suffix: '台次',
		height: 145,
		isTwoColumn: true,
		list: [
			['#1F6DFF', '维修', '-', '-'],
			['#2EB3FF', '保养', '-', '-'],
			['#A2EAFF', '易损件', '-', '-'],
			['#DBDDE0', '整备', '-', '-'],
		],
	}
	state.rangeMaintainInfo = {
		title: '期间累计维保数据',
		sumTitle: '维保总数',
		suffix: '台次',
		height: 145,
		radius: ['50%', '90%'],
		roseType: 'radius',
		isTwoColumn: true,
		list: [
			['#1F6DFF', '维修', '-', '-'],
			['#2EB3FF', '保养', '-', '-'],
			['#A2EAFF', '易损件', '-', '-'],
			['#DBDDE0', '整备', '-', '-'],
		],
	}
	state.todayMaintainSumList = [
		{
			title: '本日维修总金额',
			list: [
				['-', '万元'],
				['维修', '-', '台次'],
				['较7日前', '-'],
				['较30日前', '-'],
			],
		},
		{
			title: '本日保养总金额',
			list: [
				['-', '万元'],
				['保养', '-', '台次'],
				['较7日前', '-'],
				['较30日前', '-'],
			],
		},
		{
			title: '本日易损件更换总金额',
			list: [
				['-', '万元'],
				['易损件更换', '-', '台次'],
				['较7日前', '-'],
				['较30日前', '-'],
			],
		},
		{
			title: '本日车辆整备支出总金额',
			list: [
				['-', '万元'],
				['整备支出', '-', '台次'],
				['较7日前', '-'],
				['较30日前', '-'],
			],
		},
		{
			title: '本日车辆整备收入总金额',
			list: [
				['-', '万元'],
				['整备收入', '-', '台次'],
				['较7日前', '-'],
				['较30日前', '-'],
			],
		},
	]
	state.rangeMaintainSumList = [
		{
			title: '期间累计维修金额',
			list: [
				['-', '万元'],
				['累计维修', '-', '台次'],
				['环比', '-'],
				['同比', '-'],
			],
		},
		{
			title: '期间累计保养金额',
			list: [
				['-', '万元'],
				['累计保养', '-', '台次'],
				['环比', '-'],
				['同比', '-'],
			],
		},
		{
			title: '期间累计易损件更换金额',
			list: [
				['-', '万元'],
				['累计更换', '-', '台次'],
				['环比', '-'],
				['同比', '-'],
			],
		},
		{
			title: '期间车辆整备支出总金额',
			list: [
				['-', '万元'],
				['整备支出', '-', '台次'],
				['环比', '-'],
				['同比', '-'],
			],
		},
		{
			title: '期间车辆整备收入总金额',
			list: [
				['-', '万元'],
				['整备收入', '-', '台次'],
				['环比', '-'],
				['同比', '-'],
			],
		},
	]
	state.todayAccidentInfo = {
		title: '本日事故数据',
		sumTitle: '出险事故总数',
		suffix: '辆',
		height: 183,
		isTwoColumn: true,
		list: [
			['#39BF66', '全责', '-', '-'],
			['#9CDFB2', '主责', '-', '-'],
			['#6DCE20', '平责', '-', '-'],
			['#B6E68F', '次责', '-', '-'],
			['#DBDDE0', '无责', '-', '-'],
		],
	}
	state.rangeAccidentInfo = {
		title: '期间累计事故数据',
		sumTitle: '出险事故总数',
		suffix: '辆',
		height: 183,
		isTwoColumn: true,
		roseType: 'radius',
		radius: ['50%', '90%'],
		list: [
			['#39BF66', '全责', '-', '-'],
			['#9CDFB2', '主责', '-', '-'],
			['#6DCE20', '平责', '-', '-'],
			['#B6E68F', '次责', '-', '-'],
			['#DBDDE0', '无责', '-', '-'],
		],
	}
	state.todayAccidentList = [
		{
			title: '本日有责事故总数',
			list: [['-', '辆'], [], ['较7日前', '-'], ['较30日前', '-']],
		},
		{
			title: '本日保险上浮收入',
			list: [['-', '万元'], [], ['较7日前', '-'], ['较30日前', '-']],
		},
		{
			title: '本日事故维修返利',
			list: [['-', '万元'], [], ['较7日前', '-'], ['较30日前', '-']],
		},
	]
	state.rangeAccidentList = [
		{
			title: '期间有责事故总数',
			list: [['-', '辆'], [], ['环比', '-'], ['同比', '-']],
		},
		{
			title: '期间保险上浮收入',
			list: [['-', '万元'], [], ['期间环比', '-'], ['期间同比', '-']],
		},
		{
			title: '期间事故维修返利',
			list: [['-', '万元'], [], ['期间环比', '-'], ['期间同比', '-']],
		},
	]
}
initData()
fetchCompanyList()
</script>

<style lang="less">
.yi-shortcus {
	.el-picker-panel__sidebar {
		width: 270px;
		display: flex;
		flex-wrap: wrap;
		padding: 20px 0 8px 20px;
		align-items: flex-start;
		justify-content: flex-start;
		bottom: inherit;
		border-right: none;
	}
	.el-picker-panel__shortcut {
		flex: 108px 0 0;
		height: 34px;
		background: #f4f6f8;
		margin: 0 12px 12px 0;
		text-align: center;
		padding: 0;
	}
	.el-date-range-picker.has-sidebar {
		width: 784px;
	}
	.el-picker-panel__sidebar + .el-picker-panel__body {
		margin-left: 270px;
		border-left: 1px solid #e4e7ed;
	}
}
</style>
<style lang="less" scoped>
.year-data {
	box-sizing: border-box;
	flex: 390px 1 1;
	padding: 15px;
	background-color: #f9fafb;
	margin-right: 20px;
	height: 300px;
	h5 {
		font-size: 18px;
		font-weight: 600;
		color: #242b30;
		margin: 0;
	}
	.yd-c {
		background-color: #fff;
		margin-top: 16px;
		height: calc(100% - 16px);
	}
	.yd-i {
		flex: 1;
		text-align: center;
		.count {
			font-size: 14px;
			line-height: 14px;
			font-weight: 600;
			color: #242b30;
			padding-top: 4px;
		}
	}
	.tit {
		font-size: 14px;
		line-height: 14px;
		font-weight: 600;
		color: #6a7377;
		padding-bottom: 10px;
	}
	.number {
		font-size: 24px;
		line-height: 25px;
		color: #ff9423;
		font-weight: 700;
		vertical-align: -1px;
		font-family: DIN-Alternate, PingFang SC, sans-serif;
	}
	.split {
		width: 1px;
		height: 20px;
		background: #eee;
	}
	.yd-r {
		height: 50%;
		.flexible(row, center, space-between);
		// padding: 23px 0 18px;
		margin: 0 15px;
		&.b-b {
			border-bottom: 1px solid #eee;
		}
	}
}
.column-2 {
	margin-top: 15px;
	flex: 1;
}
.fx-start {
	.flexible(row, center, flex-start);
}
.el-select {
	width: 300px;
	:deep(.el-input) {
		height: 38px;
		line-height: 38px;
	}
	:deep(.el-input__wrapper) {
		box-shadow: none;
	}
	:deep(.el-input.is-focus .el-input__wrapper) {
		box-shadow: none !important;
	}
	:deep(.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after) {
		display: none !important;
	}
}
.h-wrap {
	.flexible(row, center, flex-start);
}
.row {
	.flexible(row, flex-start, flex-start);
}
.head {
	font-size: 18px;
	line-height: 18px;
	font-weight: 600;
	color: #242b30;
}
.mt-10 {
	margin-top: 10px;
}
.mt-15 {
	margin-top: 15px;
}
.mr-15 {
	margin-right: 15px;
}
.mt-12 {
	margin-top: 16px;
}
.container {
	overflow-x: auto;
}
.scroll-x {
	min-width: 1349px;
}
.panel {
	background-color: #fff;
	padding: 20px 0 15px 15px;
}
:deep(.el-date-editor) {
	width: 224px;
	flex: 224px 0 0;
	margin-left: 10px;
	height: 38px;
	line-height: 38px;
	background-color: #fff;
	border: none;
	box-shadow: none;
	&.is-active:hover {
		box-shadow: none;
	}
}
.option-bottom {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 32px;
	margin: 0 16px;
}
</style>
