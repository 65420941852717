<!-- eslint-disable no-undef -->
<template>
	<div class="c-d-b">
		<label>{{ label }}</label>
		<div v-if="isRange && state.currRangeIndex === 1" class="week">第{{ state.week }}周</div>
		<div class="p-n">
			<div
				v-if="hasBtn"
				class="p-n-btn"
				:class="{ disabled: !state.hasPrev }"
				@click="toPrevOrNext('prev')"
			>
				<el-icon :size="8">
					<ArrowLeft />
				</el-icon>
			</div>
			<div v-if="isRange" class="d">{{ state.rangeDate[0] }} ~ {{ state.rangeDate[1] }}</div>
			<div v-else class="d">{{ state.date }} {{ dayName }}</div>
			<div
				v-if="hasBtn"
				class="p-n-btn"
				:class="{ disabled: !state.hasNext }"
				@click="toPrevOrNext('next')"
			>
				<el-icon :size="8">
					<ArrowRight />
				</el-icon>
			</div>
		</div>
		<div v-if="isRange" class="r-l">
			<div
				v-for="(r, index) in state.rangeList"
				class="r-l-i"
				:key="r.label"
				:class="{ on: index === state.currRangeIndex, disabled: r.disabled }"
				@click="changeRange(index)"
			>
				{{ r.label }}
			</div>
		</div>
	</div>
</template>

<script setup>
import { reactive, onMounted, watch, computed } from 'vue'
import dayjs from 'dayjs'

const _rangeList = ['统计周期', '周', '月']
const _dayList = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
// eslint-disable-next-line no-undef
const props = defineProps({
	parentDate: {
		type: Array,
		default: () => [],
	},
	label: {
		type: String,
		default: '数据时间：',
	},
	// 是一个范围日期嘛
	isRange: {
		type: Boolean,
		default: false,
	},
})
// eslint-disable-next-line no-undef
const emits = defineEmits(['change'])
const state = reactive({
	currRangeIndex: 0, // 当前统计周期索引
	date: '',
	day: '',
	week: '',
	hasPrev: false,
	hasNext: false,
	rangeDate: [],
	rangeList: [..._rangeList.map(item => ({ label: item, disabled: false }))],
})
const dayName = computed(() => _dayList[state.day || 0])
const hasBtn = computed(() => state.currRangeIndex !== 0 || !props.isRange)
watch(
	() => props.parentDate,
	val => {
		initData(val)
	},
)
const setBtnStatus = () => {
	const first = dayjs(state.rangeDate[0])
	const last = dayjs(state.rangeDate[1])
	if (props.isRange) {
		if (state.currRangeIndex === 1) {
			// 当前日期的周一，切换到上周的周一，日期如果比开始日期大于7天以上，则有左边切换按钮
			state.hasPrev = first.set('day', 1).diff(props.parentDate[0], 'day') >= 7
			// 当前日期的加7天并且切换到周天，如果和结束日期的差距多余7天以上则有右边的切换按钮，但是最后一个周期的右边允许切换到parentDate[1]
			state.hasNext = first.set('day', 1).add(6, 'day').diff(props.parentDate[1], 'day') <= -1
		} else if (state.currRangeIndex === 2) {
			// 切换到上个月的1号，如果和开始时间的差距大于0天以上则有左切换按钮
			state.hasPrev =
				first
					.month(first.month() - 1)
					.date(1)
					.diff(props.parentDate[0], 'day') >= 0
			// 切换到下个月的最后一天，如果和结束时间的差距小于0天以上则有右切换按钮，如果是最后一个月，则小于该月的天数
			const m = last.month(last.month() + 1)
			state.hasNext = m.endOf('month').diff(props.parentDate[1], 'day') < m.daysInMonth()
		}
	} else {
		state.hasPrev = first.diff(props.parentDate[0], 'day') >= 1
		state.hasNext = last.diff(props.parentDate[1], 'day') <= -1
	}
}
const changeRange = index => {
	if (index === state.currRangeIndex) return
	if (state.rangeList[index].disabled) return
	state.currRangeIndex = index
	const dates = props.parentDate.map(date => date.split('-').join('/'))
	if (index === 0) {
		state.rangeDate = [...dates]
	} else if (index === 1) {
		// 当结束时间是周天的时候会出现计算出的开始日期大于结束日期的问题，所以需要提前减去一天再计算
		const last = dayjs(dates[1]).subtract(1, 'day')
		const nF = last.day(1)
		state.rangeDate = [nF.format('YYYY/MM/DD'), dates[1]]
		state.week = last.week()
	} else {
		const nF = dayjs(dates[1]).date(1)
		state.rangeDate = [nF.format('YYYY/MM/DD'), dates[1]]
	}
	setBtnStatus()
	emits('change', { range: state.rangeDate, type: state.currRangeIndex })
}
const toPrevOrNext = direction => {
	if (direction === 'prev' && !state.hasPrev) return
	if (direction === 'next' && !state.hasNext) return
	const first = dayjs(state.rangeDate[0])
	const last = dayjs(state.rangeDate[1])
	if (props.isRange) {
		if (state.currRangeIndex === 1) {
			let nF = '',
				nL = ''
			if (direction === 'prev') {
				nF = first.subtract(7, 'day')
				nL = nF.add(6, 'day')
			} else {
				nF = first.add(7, 'day')
				const diff = dayjs(props.parentDate[1]).diff(last, 'day')
				// 最后一次右切换，不用限制7天
				if (diff < 7) {
					nL = last.add(diff, 'day')
				} else {
					nL = last.add(7, 'day')
				}
			}
			state.rangeDate = [nF.format('YYYY/MM/DD'), nL.format('YYYY/MM/DD')]
			state.week = nL.subtract(1, 'day').week()
		} else if (state.currRangeIndex === 2) {
			let nMonth = first.month()
			if (direction === 'prev') {
				nMonth--
			} else {
				nMonth++
			}
			const nF = first.month(nMonth).date(1)
			let nL = ''
			const diff = dayjs(props.parentDate[1]).diff(last, 'day')
			// 最后一次右切换，不用限制当月的天数
			if (direction === 'next' && diff < last.month(nMonth).daysInMonth()) {
				nL = last.month(nMonth).date(diff)
			} else {
				nL = last.month(nMonth).endOf('month')
			}
			state.rangeDate = [nF.format('YYYY/MM/DD'), nL.format('YYYY/MM/DD')]
		}
		setBtnStatus()
		emits('change', { range: state.rangeDate, type: state.currRangeIndex })
	} else {
		const currDate = dayjs(state.date)
		let nDate = ''
		if (direction === 'prev') {
			nDate = currDate.subtract(1, 'day')
		} else {
			nDate = currDate.add(1, 'day')
		}
		state.date = nDate.format('YYYY/MM/DD')
		state.day = nDate.get('day')
		state.hasPrev = nDate.diff(props.parentDate[0], 'day') >= 1
		state.hasNext = nDate.diff(props.parentDate[1], 'day') <= -1
		emits('change', { date: state.date })
	}
}
const initData = (dateList = []) => {
	if (dateList.length !== 2) return
	dateList = dateList.map(date => date.split('-').join('/'))
	const first = dayjs(dateList[0])
	const last = dayjs(dateList[1])

	if (props.isRange) {
		state.rangeDate = [...dateList]
		state.rangeList = [..._rangeList.map(item => ({ label: item, disabled: false }))]

		// 判断是否是完整周（周一到周天）
		if (first.get('day') === 1 && last.diff(dateList[0], 'day') === 6) {
			state.currRangeIndex = 1
			// 判断第几周，因为传统的周是从周天到周六算一周，所以这里需要减去一天
			state.week = last.subtract(1, 'day').week()
		} else if (first.get('date') === 1 && last.get('date') === last.endOf('month').get('date')) {
			// 是否是完整月
			state.currRangeIndex = 2
		} else {
			state.currRangeIndex = 0

			// 判断周按钮是可以用
			if (first.isAfter(last.subtract(1, 'day').day(1))) {
				state.rangeList.splice(1, 1, { ...state.rangeList[1], disabled: true })
			}
			// 判断月按钮是否可以用
			if (first.isAfter(last.date(1))) {
				state.rangeList.splice(2, 1, { ...state.rangeList[2], disabled: true })
			}
		}
		emits('change', { range: state.rangeDate, type: state.currRangeIndex })
	} else {
		state.date = dateList[1]
		state.day = last.get('day')
		const currDate = dayjs(state.date)
		state.hasPrev = currDate.diff(dateList[0], 'day') >= 1
		state.hasNext = currDate.diff(dateList[1], 'day') <= -1
		emits('change', { date: state.date })
	}
}
onMounted(() => {
	initData(props.parentDate)
})
</script>

<style lang="less" scoped>
.c-d-b {
	.flexible(row, center, flex-start);
	font-size: 14px;
	color: #6a7377;
	font-weight: 400;
	line-height: 24px;
	.week {
		margin-left: 10px;
	}
	.p-n {
		.flexible(row, center, flex-start);
		margin-left: 5px;
	}
	.r-l {
		margin-left: 15px;
		.flexible(row, center, flex-start);
	}
	.r-l-i {
		width: 76px;
		height: 22px;
		background: #fff;
		color: #242b30;
		font-size: 14px;
		border: 1px solid #eee;
		margin-right: 10px;
		cursor: pointer;
		text-align: center;
		line-height: 20px;
		user-select: none;
		box-sizing: border-box;
		&.on {
			color: #ff9423;
			border-color: #ff9423;
		}
		&.disabled {
			color: #a2acb1;
			background-color: #f4f6f8;
			border-color: #f4f6f8;
			cursor: not-allowed;
		}
	}
	.p-n-btn {
		.flexible(row, center, center);
		padding: 5px;
		cursor: pointer;
		&.disabled {
			cursor: not-allowed;
			.el-icon {
				opacity: 0.5;
			}
		}
	}
	.el-icon {
		width: 14px;
		height: 14px;
		border-radius: 2em;
		background: rgba(219, 221, 224, 1);
	}
	.d {
		padding: 0 3px;
		user-select: none;
	}
}
</style>
